import {
    Component,
    ViewEncapsulation,
    TemplateRef,
    EffectRef,
    effect,
    OnDestroy,
    ChangeDetectionStrategy,
    // signal,
    ViewChildren
} from '@angular/core';
import {
    GridService,
    MapService,
    ConfigService,
    SidenavService
} from 'app/_services';
import { MediaDialogComponent } from './dialogs/media.dialog';
import { MatDialog } from '@angular/material/dialog';
import { faFileExport, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Style, Fill, Stroke, Circle } from 'ol/style';
import Feature from 'ol/Feature';
import { SelectExportDialogComponent } from 'app/_dialogs';
import { Geometry } from 'ol/geom';
import { VrViewerDialogComponent } from 'app/tools/vr-photo/vr-viewer/vr-viewer';
import { StyleLike } from 'ol/style/Style';
import { MatSort } from '@angular/material/sort';

// import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { QueryList } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AfterViewInit } from '@angular/core';
import { EditGridDialog } from './dialogs/edit-grid.dialog';

export interface ClickItem {
    id: number;
    source: any;
    layers: GridLayer[];
    name: string;
    columnName: string;
    columns: Column[];
}

export interface Column {
    name: string;
    prop: string;
    type?: string;
    sort?: string;
    width?: number;
    cellTemplate?: TemplateRef<any>;
}

export interface GridLayer {
    title: string;
    features: Feature<Geometry>[];
}

export interface GridItem {
    columnName: string;
    name: string;
    type: string;
    graph: {
        x: number;
        type: string;
    };
    columns: Column[];
}

@Component({
    selector: 'grid',
    templateUrl: 'grid.component.html',
    styleUrls: ['grid.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridComponent implements OnDestroy, AfterViewInit {
    readonly faFileExport = faFileExport;
    readonly faAngleDown = faAngleDown;
    private readonly originalFeatures: any[] = [];
    private regularStyle: StyleLike;

    customGrids: any = [];

    private exportFeatures: any[] = [];
    private highlightedFeature: Feature<Geometry>;

    readonly chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        }
    };

    features = [];
    private gridConfig: GridItem[];
    readonly chartColors = ['#7DB3FF', '#49457B', '#FF7C78', 'rgb(255,205,86)'];

    private configSubscription: EffectRef;
    private _featureSubscription: Subscription;

    @ViewChildren(MatSort) sorts: QueryList<MatSort>;
    @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

    constructor(
        readonly gridService: GridService,
        readonly sidenavService: SidenavService,
        private readonly dialog: MatDialog,
        private readonly mapService: MapService,
        private readonly configService: ConfigService,
        private cdr: ChangeDetectorRef
    ) {
        this.configSubscription = effect(() => {
            const config = this.configService.config();

            if (!config) return;

            this.gridConfig = config.tools.grid ?? [];
            this.createColumns();
        });
    }

    ngAfterViewInit(): void {
        this.updateSort();
    }

    updateSort() {
        this.sorts.changes.subscribe((sorts: QueryList<MatSort>) => {
            sorts.forEach((sort, index) => {
                if (this.features[index]) {
                    this.features[index].sort = sort;
                }
            });
        });
    }

    ngOnDestroy(): void {
        this.configSubscription.destroy();
        this._featureSubscription.unsubscribe();
    }

    export(type): void {
        this.dialog.open(SelectExportDialogComponent, {
            panelClass: 'SelectExportDialogComponent',
            data: {
                features: this.exportFeatures[type.id],
                title: type.title
            }
        });
    }

    private highlightStyle() {
        // Feel free to create a better highlight styling
        return new Style({
            fill: new Fill({
                color: 'rgba(0, 51, 255, 0.5)'
            }),
            stroke: new Stroke({
                color: '#0033ff',
                width: 2
            }),
            image: new Circle({
                radius: 7,
                fill: new Fill({
                    color: '#0033ff'
                })
            })
        });
    }

    highlightFeature(feature: any): void {
        if (this.highlightedFeature) {
            this.highlightedFeature.setStyle(this.regularStyle);
        }

        this.highlightedFeature = this.originalFeatures.find(
            originalFeature => originalFeature.getId() === feature.id_
        );

        if (this.highlightedFeature) {
            this.regularStyle = this.highlightedFeature.getStyle();

            this.highlightedFeature.setStyle(this.highlightStyle());

            const geometry = this.highlightedFeature.getGeometry();
            if (geometry) {
                const view = this.mapService.map().getView();
                view.fit(geometry.getExtent(), {
                    padding: [200, 200, 300, 200],
                    maxZoom: 17,
                    duration: 2000
                });
            }
        }
    }

    openImageDialog(data): void {
        this.dialog.open(MediaDialogComponent, {
            data: {
                title: 'Afbeelding',
                imageSrc: data
            }
        });
    }

    openVideoDialog(videoSrc): void {
        this.dialog.open(MediaDialogComponent, {
            data: {
                title: 'Video',
                videoSrc: videoSrc
            }
        });
    }

    openVRDialog(imageSrc): void {
        this.dialog.open(VrViewerDialogComponent, {
            height: '80vh',
            width: '75vw',
            data: {
                imageUrl: imageSrc
            }
        });
    }

    createColumns(): void {
        // Subscribe to the features of the GridService so we get updated values once they've been pushed to the service
        this._featureSubscription = this.gridService.featuresChange.subscribe(
            (clicks: ClickItem[]) => {
                this.handleGridClick(clicks);
            }
        );
    }

    private handleGridClick(clicks: ClickItem[]): void {
        // Reset the features
        this.features = undefined;

        // Create a list of natural names for the types
        const hardcodedTypes = {
            //Duopp
            verhard_opp: 'Verhard Oppervlak',
            verhard_oppervlak: 'Verhard Oppervlak',
            rivierenland_duikers: 'Rivierenland Legger Duikers',
            rivierenland_stuwen: 'Rivierenland Legger Stuwen',
            projecten_riolering_buren: 'Projecten Riolering Buren',
            projecten_overig_buren: 'Projecten Overig Buren',
            //GWSW sets
            gwsw_voorzieningen: 'GWSW Voorziening',
            gwsw_putten: 'GWSW Putten',
            gwsw_leidingen: 'GWSW Leidingen',
            gwsw_put_onderdelen: 'GWSW Put Onderdeel',
            gwsw_leiding_onderdelen: 'GWSW Leiding Onderdeel',
            gwsw_gebieden: 'GWSW Gebied',
            //DUOPP nulmeting
            gwsw_leidingen_nulmeting: 'GWSW Nulmeting Leidingen (Duopp)',
            gwsw_put_nulmeting: 'GWSW Nulmeting Putten (Duopp)',
            gwsw_put_onderdeel_nulmeting:
                'GWSW Nulmeting Put Onderdelen (Duopp)',
            gwsw_leiding_onderdeel_nulmeting:
                'GWSW Nulmeting Leiding Onderdelen (Duopp)',
            //Duopp afvoerpunten/relaties
            gwsw_afvoerpunten: 'GWSW Afvoerpunten',
            gwsw_afvoerrelaties: 'GWSW Afvoerrelaties',
            gwsw_blokkenrelaties: 'GWSW Blokkenrelaties',
            gwsw_kentallen: 'GWSW Kentallen',
            //Duopp riooltekeningen
            duopp_riooltekeningen: 'Riooltekeningen',
            duopp_huisaansluitschetsen: 'Huisaansluitschetsen',
            duopp_geboorde_leidingen: 'Geboorde leidingen',
            duopp_riooltekeningen_overig: 'Overige tekeningen',
            duopp_putfoto: 'Putfoto',
            duopp_rapportage: 'Rapportage',
            //Leiden huisaansluitschetsen
            leiden_huisaansluitschetsen: 'Huisaansluitschetsen Leiden',
            //Beoordeling inspecties
            beoordeling_inspecties: 'Beoordeling inspecties',
            //wegbeheer buren
            wegbeheer: 'Wegbeheer',
            //maatregelen_onderhoud
            maatregelen_onderhoud: 'Maatregelen riolering',
            putvideo_inspectie: 'Putvideo inspectie',
            //Beheerpakketen
            oms_h2go: 'Beheerpakket OMS H2GO',
            //Groenvakken Buren
            buren_groenvakken: 'Groenvakken',
            //Groenbeheer Buren
            buren_geovisia_groenvakken: 'Geovisia Groenvakken',
            buren_geovisia_bomen: 'Geovisia Bomen',
            //Buren Riosonic
            buren_riosonic: 'Onderzoek riosonic'
        };

        // Remap these feature types by combining them into one.
        const remapTypes: any = {
            //BAG Panden
            bag_standplaats: 'bag_panden',
            bag_ligplaats: 'bag_panden',
            bag_gebouwen_voorlopig: 'bag_panden',
            bag_gebouwen_gestart: 'bag_panden',
            bag_gebouwen: 'bag_panden',
            bag_gebouwen_overiggebouwdeobjecten: 'bag_panden',
            bag_gebouwen_historich: 'bag_panden',
            bag_gebouwen_verbouwing: 'bag_panden',
            //BGT
            bgt_begroeidterreindeel: 'bgt_data',
            bgt_onbegroeidterreindeel: 'bgt_data',
            bgt_ondersteunendwegdeel: 'bgt_data',
            bgt_wegdeel: 'bgt_data',
            bgt_wegdeel_plus: 'bgt_data',
            bgt_waterdeel: 'bgt_data',
            bgt_ondersteunendwaterdeel: 'bgt_data',
            bgt_overbruggingsdeel: 'bgt_data',
            // Tijdelijk uitgezet vanwege bug met mapserver
            //"bgt_pand": "bgt_data",
            bgt_waterinrichtingselement_line: 'bgt_data',
            bgt_waterinrichtingselement_point: 'bgt_data',
            bgt_nummeraanduidingreeks: 'bgt_data',
            bgt_tunneldeel: 'bgt_data',
            bgt_spoor: 'bgt_data',
            bgt_kunstwerkdeel_vlak: 'bgt_data',
            bgt_kunstwerkdeel_point: 'bgt_data',
            bgt_kunstwerkdeel_line: 'bgt_data',
            bgt_scheiding_vlak: 'bgt_data',
            bgt_scheiding_line: 'bgt_data',
            bgt_scheiding_plus: 'bgt_data',
            bgt_functioneelgebied_vlakken: 'bgt_data',
            bgt_functioneelgebied_point: 'bgt_data',
            bgt_overigbouwwerk: 'bgt_data',
            bgt_overigbouwwerk_plus: 'bgt_data',
            bgt_overige_scheiding_line: 'bgt_data',
            bgt_overige_scheiding_plus: 'bgt_data',
            bgt_bak: 'bgt_data',
            bgt_bord: 'bgt_data',
            bgt_gebouwinstallatie: 'bgt_data',
            bgt_installatie: 'bgt_data',
            bgt_kast: 'bgt_data',
            bgt_mast: 'bgt_data',
            bgt_paal: 'bgt_data',
            bgt_put: 'bgt_data',
            bgt_sensor: 'bgt_data',
            bgt_straatmeubilair: 'bgt_data',
            bgt_weginrichtingselement_line: 'bgt_data',
            bgt_weginrichtingselement_point: 'bgt_data',
            bgt_weginrichtingselement_polygon: 'bgt_data',
            bgt_vegetatieobject_line: 'bgt_data',
            bgt_vegetatieobject_point: 'bgt_data',
            bgt_openbareruimtelabel: 'bgt_data',
            bgt_ongeclassificeerd_polygon: 'bgt_data',
            bgt_ongeclassificeerd_line: 'bgt_data',
            bgt_ongeclassificeerd_point: 'bgt_data',
            //CGM
            //Duopp Stelsel en Systemen - putten
            rioned_put_inspectieputten1: 'rioolknooppunten_rioned',
            rioned_put_pompput1: 'rioolknooppunten_rioned',
            rioned_put_rioolgemaal1: 'rioolknooppunten_rioned',
            rioned_put_externeoverstortput1: 'rioned_beheer_lozing',
            rioned_put_interneoverstortput1: 'rioned_beheer_lozing',
            rioned_put_overstortput1: 'rioned_beheer_lozing',
            rioned_put_uitlaatconstructie1: 'rioolknooppunten_rioned',
            rioned_put_afsluiter1: 'rioolknooppunten_rioned',
            rioned_put_lozingsput1: 'rioolknooppunten_rioned',
            rioned_put_pompunit1: 'rioolknooppunten_rioned',
            rioned_put_stuwput1: 'rioolknooppunten_rioned',
            rioned_put_rioolput1: 'rioolknooppunten_rioned',
            rioned_put_kruisingsput1: 'rioolknooppunten_rioned',
            rioned_put_troittoirkolk1: 'rioolknooppunten_rioned',
            rioned_put_bergbezinkbassin1: 'rioolknooppunten_rioned',
            rioned_put_wervelput1: 'rioolknooppunten_rioned',
            rioned_put_rwzi1: 'rioolknooppunten_rioned',
            rioned_put_kolk1: 'rioolknooppunten_rioned',
            rioned_put_straatkolk1: 'rioolknooppunten_rioned',
            rioned_put_zinkerput1: 'rioolknooppunten_rioned',
            rioned_put_iba1: 'rioolknooppunten_rioned',
            //Duopp GWSW MDSplan nulmeting
            gwsw_nulmeting_leiding_type_melding_waarde_te_klein:
                'gwsw_nulmeting',
            gwsw_nulmeting_leiding_type_melding_waarde_te_groot:
                'gwsw_nulmeting',
            gwsw_nulmeting_leiding_type_melding_objecttype: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_type_melding_ontbrekende_relatie_hasaspect:
                'gwsw_nulmeting',
            gwsw_nulmeting_leiding_type_melding_onbekend_ontologie:
                'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_bob_beginpunt: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_bob_eindpunt: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_breedte: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_hoogte: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_lengte: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_materiaal: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_vorm: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_kenmerk_vrijverval: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_opmerking_waarde_te_groot: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_opmerking_waarde_te_klein: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_opmerking_aantal_relaties: 'gwsw_nulmeting',
            gwsw_nulmeting_leiding_opmerking_niet_betrouwbaar: 'gwsw_nulmeting',
            gwsw_nulmeting_put_type_melding_ontbrekende_relatie_hasaspect:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_type_melding_ontbrekende_relatie_haspart:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_type_melding_waarde_te_klein: 'gwsw_nulmeting',
            gwsw_nulmeting_put_type_melding_objecttype_te_globaal:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_type_melding_onbekend_ontologie:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_type_melding_waarde_te_groot: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_overstortdrempel: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_vorm_put: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_materiaal_put: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_compartiment: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_lengte: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_breedte: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_pompput: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_pomp: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_punt: 'gwsw_nulmeting',
            gwsw_nulmeting_put_kenmerk_stuwmuur: 'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_te_klein: 'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_te_klein_10: 'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_te_klein_0: 'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_aantal_relaties_hasaspect:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_aantal_relaties_haspart:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_vervolg_validatie_niet_betrouwbaar:
                'gwsw_nulmeting',
            gwsw_nulmeting_put_opmerking_te_groot: 'gwsw_nulmeting',
            //DUOPP Nulmeting
            rioned_rioolstreng_stelsel_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_stelsel_bekend: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_fundering_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_fundering_bekend: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_verbindingstype_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_verbindingstype_fout: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_verbindingstype_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_materiaalleiding_null:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_materiaalleiding_fout:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_materiaalleiding_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_vormleiding_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_vormleiding_foutief: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_vormleiding_bekend: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_breedteleiding_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_breedteleiding_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_hoogteleiding_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_hoogteleiding_bekend: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobbeginpuntleiding_null:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobbeginpuntleiding_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobeindpuntleiding_null:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobeindpuntleiding_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobbeginpuntleiding_vrijverval_null:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobbeginpuntleiding_vrijverval_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobeindpuntleiding_vrijverval_null:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_bobeindpuntleiding_vrijverval_bekend:
                'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_leeftijd_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_leeftijd_bekend: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_naam_gebied_null: 'gwsw_leidingen_nulmeting',
            rioned_rioolstreng_naam_gebied_bekend: 'gwsw_leidingen_nulmeting',
            rioned_put_maaiveldhoogte_null: 'gwsw_put_nulmeting',
            rioned_put_maaiveldhoogte_bekend: 'gwsw_put_nulmeting',
            rioned_put_materiaalput_null: 'gwsw_put_nulmeting',
            rioned_put_materiaalput_fout: 'gwsw_put_nulmeting',
            rioned_put_materiaalput_bekend: 'gwsw_put_nulmeting',
            rioned_put_vormput_null: 'gwsw_put_nulmeting',
            rioned_put_vormput_fout: 'gwsw_put_nulmeting',
            rioned_put_vormput_bekend: 'gwsw_put_nulmeting',
            rioned_put_breedteput_null: 'gwsw_put_nulmeting',
            rioned_put_breedteput_bekend: 'gwsw_put_nulmeting',
            rioned_put_lengteput_null: 'gwsw_put_nulmeting',
            rioned_put_lengteput_bekend: 'gwsw_put_nulmeting',
            rioned_put_hoogteput_null: 'gwsw_put_nulmeting',
            rioned_put_hoogteput_bekend: 'gwsw_put_nulmeting',
            rioned_put_aantal_pompen_null: 'gwsw_put_nulmeting',
            rioned_put_aantal_pompen_bekend: 'gwsw_put_nulmeting',
            rioned_put_overstortdrempel_aantal_null: 'gwsw_put_nulmeting',
            rioned_put_overstortdrempel_aantal_bekend: 'gwsw_put_nulmeting',
            rioned_put_onderdeel_pompcapaciteit_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_pompcapaciteit_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_overstortdrempel_breedte_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_overstortdrempel_breedte_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_overstortdrempel_hoogte_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_overstortdrempel_hoogte_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_overstortdrempel_stromingsrichting_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_overstortdrempel_stromingsrichting_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_null: 'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_type_bekend: 'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_type_fout: 'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_breedte_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_breedte_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_hoogte_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_hoogte_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_vorm_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_vorm_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_contractiecoef_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_contractiecoef_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_maxcapdoorlaat_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_maxcapdoorlaat_bekend:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_stromingsrichting_null:
                'gwsw_put_onderdeel_nulmeting',
            rioned_put_onderdeel_doorlaat_stromingsrichting_bekend:
                'gwsw_put_onderdeel_nulmeting',
            afvoerrelatie_begin_eind_onbekend: 'gwsw_put_onderdeel_nulmeting',
            afvoerrelatie_begin_eind_bekend: 'gwsw_put_onderdeel_nulmeting',
            afvoerrelatie_pomp: 'gwsw_put_onderdeel_nulmeting',
            afvoerrelatie_doorlaat: 'gwsw_put_onderdeel_nulmeting',
            afvoerrelatie_overstortdrempel: 'gwsw_put_onderdeel_nulmeting',
            rioned_leiding_onderdeel_type_bekend:
                'gwsw_leiding_onderdeel_nulmeting',
            rioned_leiding_onderdeel_type_fout:
                'gwsw_leiding_onderdeel_nulmeting',
            //Duopp afwateringskaart
            afwateringskaart_sa_gebied_gwsw_type_stelsel_gemengd:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_stelsel_hemelwaterstelsel_gs:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_stelsel_hemelwaterstelsel_vgs:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_stelsel_gedeeltelijk_aangesloten:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_stelsel_niet_aangesloten:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_ja_gedeeltelijk: 'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_aangesloten: 'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_niet_aangesloten:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_verharding_dakhellend:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_verharding_dakvlak:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_verharding_dakuitgestrektdakvlak:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_verharding_erf_onverhard:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_verharding_erf_verhard:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_gesloten_verharding:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_open_verharding:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_open_verharding_uitgestrekt:
                'afwateringskaart',
            afwateringskaart_sa_gebied_gwsw_type_verharding_niet_aangesloten:
                'afwateringskaart',
            //Duopp  reiniging en inspectie
            reining_inspectie_alleen_reiniging: 'reiniging',
            reining_inspectie_reiniging_en_inspectie: 'reiniging',
            //Duopp putvideo inspectie
            onderhoud_putvideoinspectie_niet_uitgevoerd: 'putvideo_inspectie',
            onderhoud_putvideoinspectie_uitgevoerd: 'putvideo_inspectie',
            onderhoud_putvideoinspectie_inspectieplan_2023_niet_uitgevoerd:
                'putvideo_inspectie',
            onderhoud_putvideoinspectie_inspectieplan_2023_uitgevoerd:
                'putvideo_inspectie',
            onderhoud_putvideoinspectie_inspectieplan_2023_let_op:
                'putvideo_inspectie',
            onderhoud_putvideoinspectie_inspectieplan_2023:
                'putvideo_inspectie',
            //Duopp Afvoerend Oppervlak
            verhard_opp_aangesloten1: 'verhard_opp',
            verhard_opp_gedeeltelijk_aangesloten1: 'verhard_opp',
            verhard_opp_niet_aangesloten1: 'verhard_opp',
            verhard_opp_stelsel_gemengd_stelsel1: 'verhard_opp',
            verhard_opp_stelsel_hemelwaterstelsel_gs1: 'verhard_opp',
            verhard_opp_stelsel_hemelwaterstelsel_vgs1: 'verhard_opp',
            verhard_opp_stelsel_water1: 'verhard_opp',
            verhard_opp_stelsel_niet_aangesloten1: 'verhard_opp',
            verhard_opp_stelsel_infiltratiestelsel1: 'verhard_opp',
            verhard_opp_dakoppervlak_hellend1: 'verhard_opp',
            verhard_opp_gesloten_verhard_oppervlak_hellend1: 'verhard_opp',
            verhard_opp_onverhard_oppervlak_hellend1: 'verhard_opp',
            verhard_opp_open_verhard_oppervlak_hellend1: 'verhard_opp',
            verhard_opp_dakoppervlak_vlak1: 'verhard_opp',
            verhard_opp_gesloten_verhard_oppervlak_vlak1: 'verhard_opp',
            verhard_opp_onverhard_oppervlak_vlak1: 'verhard_opp',
            verhard_opp_open_verhard_oppervlak_vlak1: 'verhard_opp',
            verhard_opp_dakoppervlak_vlak_uitgestrekt1: 'verhard_opp',
            verhard_opp_gesloten_verhard_oppervlak_vlak_uitgestrekt1:
                'verhard_opp',
            verhard_opp_onverhard_oppervlak_vlak_uitgestrekt1: 'verhard_opp',
            verhard_opp_open_verhard_oppervlak_vlak_uitgestrekt1: 'verhard_opp',
            verhard_opp_erf_onverhard_bgt1: 'verhard_opp',
            verhard_opp_erf_verhard_bgt1: 'verhard_opp',
            verhard_opp_gesloten_verharding_bgt1: 'verhard_opp',
            verhard_opp_open_verharding_bgt1: 'verhard_opp',
            verhard_opp_onverhard_bgt1: 'verhard_opp',
            verhard_opp_oppervlak_niet_aangesloten1: 'verhard_opp',
            verhard_opp_nog_te_bepalen1: 'verhard_opp',
            verhard_opp_aangesloten: 'verhard_opp',
            verhard_opp_gedeeltelijk_aangesloten: 'verhard_opp',
            verhard_opp_niet_aangesloten: 'verhard_opp',
            verhard_opp_stelsel_gemengd_stelsel: 'verhard_opp',
            verhard_opp_stelsel_hemelwaterstelsel_gs: 'verhard_opp',
            verhard_opp_stelsel_hemelwaterstelsel_vgs: 'verhard_opp',
            verhard_opp_stelsel_water: 'verhard_opp',
            verhard_opp_stelsel_niet_aangesloten: 'verhard_opp',
            verhard_opp_stelsel_infiltratiestelsel: 'verhard_opp',
            verhard_opp_dakoppervlak_hellend: 'verhard_opp',
            verhard_opp_gesloten_verhard_oppervlak_hellend: 'verhard_opp',
            verhard_opp_onverhard_oppervlak_hellend: 'verhard_opp',
            verhard_opp_open_verhard_oppervlak_hellend: 'verhard_opp',
            verhard_opp_dakoppervlak_vlak: 'verhard_opp',
            verhard_opp_gesloten_verhard_oppervlak_vlak: 'verhard_opp',
            verhard_opp_onverhard_oppervlak_vlak: 'verhard_opp',
            verhard_opp_open_verhard_oppervlak_vlak: 'verhard_opp',
            verhard_opp_dakoppervlak_vlak_uitgestrekt: 'verhard_opp',
            verhard_opp_gesloten_verhard_oppervlak_vlak_uitgestrekt:
                'verhard_opp',
            verhard_opp_onverhard_oppervlak_vlak_uitgestrekt: 'verhard_opp',
            verhard_opp_open_verhard_oppervlak_vlak_uitgestrekt: 'verhard_opp',
            verhard_opp_erf_onverhard_bgt: 'verhard_opp',
            verhard_opp_erf_verhard_bgt: 'verhard_opp',
            verhard_opp_gesloten_verharding_bgt: 'verhard_opp',
            verhard_opp_open_verharding_bgt: 'verhard_opp',
            verhard_opp_onverhard_bgt: 'verhard_opp',
            verhard_opp_oppervlak_niet_aangesloten: 'verhard_opp',
            verhard_opp_nog_te_bepalen: 'verhard_opp',
            //DUOPP verhard oppervlak nieuw
            verhard_oppervlak_aangesloten_ja: 'verhard_oppervlak',
            verhard_oppervlak_aangesloten_nee: 'verhard_oppervlak',
            verhard_oppervlak_dakoppervlak_hellend: 'verhard_oppervlak',
            verhard_oppervlak_dakoppervlak_vlak: 'verhard_oppervlak',
            verhard_oppervlak_gesloten_verhard_oppervlak_vlak:
                'verhard_oppervlak',
            verhard_oppervlak_onverhard_oppervlak_vlak: 'verhard_oppervlak',
            verhard_oppervlak_gesloten_verhard_oppervlak_hellend:
                'verhard_oppervlak',
            verhard_oppervlak_onverhard_oppervlak_hellend: 'verhard_oppervlak',
            verhard_oppervlak_open_verhard_oppervlak_hellend:
                'verhard_oppervlak',
            verhard_oppervlak_dakoppervlak_vlak_uitgestrekt:
                'verhard_oppervlak',
            verhard_oppervlak_gesloten_verhard_oppervlak_vlak_uitgestrekt:
                'verhard_oppervlak',
            verhard_oppervlak_onverhard_oppervlak_vlak_uitgestrekt:
                'verhard_oppervlak',
            verhard_oppervlak_open_verhard_oppervlak_vlak: 'verhard_oppervlak',
            verhard_oppervlak_open_verhard_oppervlak_vlak_uitgestrekt:
                'verhard_oppervlak',
            verhard_oppervlak_gemengd_stelsel: 'verhard_oppervlak',
            verhard_oppervlak_duikerstelsel: 'verhard_oppervlak',
            verhard_oppervlak_gecombineerd_infiltratiestelsel:
                'verhard_oppervlak',
            verhard_oppervlak_hemelwaterstelsel: 'verhard_oppervlak',
            verhard_oppervlak_infiltratiestelsel: 'verhard_oppervlak',
            verhard_oppervlak_verbeterd_hemelwaterstelsel: 'verhard_oppervlak',
            verhard_oppervlak_niet_aangesloten: 'verhard_oppervlak',
            verhard_oppervlak_nieuw_verbouw: 'verhard_oppervlak',
            verhard_oppervlak_afkoppelsubsidie: 'verhard_oppervlak',
            verhard_oppervlak_groendak: 'verhard_oppervlak',
            verhard_oppervlak_openstaand: 'verhard_oppervlak',
            verhard_oppervlak_afgekoppeld: 'verhard_oppervlak',
            //Duopp Rivierenland
            rivierenland_duikers_buren: 'rivierenland_duikers',
            rivierenland_duikers_maurik: 'rivierenland_duikers',
            rivierenland_duikers_weste_betuwe: 'rivierenland_duikers',
            rivierenland_duikers_prov_gelderland: 'rivierenland_duikers',
            rivierenland_duikers_staat_infra_milieu: 'rivierenland_duikers',
            rivierenland_duikers_waterschap_rivierenland:
                'rivierenland_duikers',
            rivierenland_stuwen: 'rivierenland_stuwen',
            //Duopp Projecten
            projecten_riolering_aanleg: 'projecten_riolering_buren',
            projecten_riolering_herstelwerkzaamheden:
                'projecten_riolering_buren',
            projecten_riolering_controleren: 'projecten_riolering_buren',
            projecten_anderen_ontwikkeling: 'projecten_overig_buren',
            projecten_anderen_wegbeheer: 'projecten_overig_buren',
            //Duopp GWSW sets Putten
            rioned_put_besturingskast: 'gwsw_putten',
            rioned_put_ontluchtingsunit: 'gwsw_putten',
            rioned_put_centraleverdeelkast: 'gwsw_putten',
            rioned_put_elektriciteitskast: 'gwsw_putten',
            rioned_put_externeoverstortput: 'gwsw_putten',
            rioned_put_interneoverstortput: 'gwsw_putten',
            rioned_put_overstortput: 'gwsw_putten',
            rioned_put_noodoverstortput: 'gwsw_putten',
            rioned_put_afsluiter: 'gwsw_putten',
            rioned_put_blinde_put: 'gwsw_putten',
            rioned_put_valput: 'gwsw_putten',
            rioned_put_hulpstuk: 'gwsw_putten',
            rioned_put_hulpstukorientatie: 'gwsw_putten',
            rioned_put_inlaat_leiding: 'gwsw_putten',
            rioned_put_infiltratiebassin: 'gwsw_putten',
            rioned_put_infiltratiekolk: 'gwsw_putten',
            rioned_put_ontlastput: 'gwsw_putten',
            rioned_put_stuwklep: 'gwsw_putten',
            rioned_put_verdekteput: 'gwsw_putten',
            rioned_put_vrijverval_rioolleiding: 'gwsw_putten',
            rioned_put_reinigendeput: 'gwsw_putten',
            rioned_put_put: 'gwsw_putten',
            rioned_put_lozingsput: 'gwsw_putten',
            rioned_put_pompput: 'gwsw_putten',
            rioned_put_pompunit: 'gwsw_putten',
            rioned_put_luchtpersgemaal: 'gwsw_putten',
            rioned_put_oppervlaktewatergemaal: 'gwsw_putten',
            rioned_put_luchtinjectie_unit: 'gwsw_putten',
            rioned_put_wadi: 'gwsw_putten',
            rioned_put_lijngoot: 'gwsw_putten',
            rioned_put_rioolgemaal: 'gwsw_putten',
            rioned_put_gemaal_natte_opstelling: 'gwsw_putten',
            rioned_put_uitlaatconstructie: 'gwsw_putten',
            rioned_put_goot: 'gwsw_putten',
            rioned_put_uitstroombak: 'gwsw_putten',
            rioned_put_opvoergemaal: 'gwsw_putten',
            rioned_put_gemaal: 'gwsw_putten',
            rioned_put_stuwput: 'gwsw_putten',
            rioned_put_peilbuis: 'gwsw_putten',
            rioned_put_bijzondere_putconstructie: 'gwsw_putten',
            rioned_put_infiltratiereservoir: 'gwsw_putten',
            rioned_put_fysiek_object: 'gwsw_putten',
            rioned_put_rioolput: 'gwsw_putten',
            rioned_put_kruisingsput: 'gwsw_putten',
            rioned_put_hondenhokput: 'gwsw_putten',
            rioned_put_troittoirkolk: 'gwsw_putten',
            rioned_put_bergbezinkbassin: 'gwsw_putten',
            rioned_put_wervelput: 'gwsw_putten',
            rioned_put_rwzi: 'gwsw_putten',
            rioned_put_kolk: 'gwsw_putten',
            rioned_put_doorspoelput: 'gwsw_putten',
            rioned_put_pigging_installatie: 'gwsw_putten',
            rioned_put_verbindingsstuk: 'gwsw_putten',
            rioned_put_overgangsstuk: 'gwsw_putten',
            rioned_put_verloopstuk: 'gwsw_putten',
            rioned_put_straatkolk: 'gwsw_putten',
            rioned_put_zinkerput: 'gwsw_putten',
            rioned_put_aansluitpunt: 'gwsw_putten',
            rioned_put_aansluitput: 'gwsw_putten',
            rioned_put_iba_klasse2: 'gwsw_putten',
            rioned_put_iba_klasse1: 'gwsw_putten',
            rioned_put_iba_klasse3b: 'gwsw_putten',
            rioned_put_sceptictank: 'gwsw_putten',
            rioned_put_iba: 'gwsw_putten',
            rioned_put_boostergemaal: 'gwsw_putten',
            rioned_put_bochtstuk: 'gwsw_putten',
            rioned_put_ontluchter: 'gwsw_putten',
            rioned_put_ontluchtingsventiel: 'gwsw_putten',
            rioned_put_ontstoppingsstuk: 'gwsw_putten',
            rioned_put_erfafscheidingsput: 'gwsw_putten',
            rioned_put_ontstoppingsput: 'gwsw_putten',
            rioned_put_drainageput: 'gwsw_putten',
            rioned_put_t_stuk: 'gwsw_putten',
            rioned_put_y_stuk: 'gwsw_putten',
            rioned_put_centralevoedingskast: 'gwsw_putten',
            rioned_put_schildmuur: 'gwsw_putten',
            rioned_put_terugslagklep: 'gwsw_putten',
            rioned_put_klep: 'gwsw_putten',
            rioned_put_infiltratiekrat: 'gwsw_putten',
            rioned_put_reservoir: 'gwsw_putten',
            rioned_put_filterput: 'gwsw_putten',
            rioned_put_regenmeter: 'gwsw_putten',
            rioned_put_zandvangput: 'gwsw_putten',
            rioned_put_onbekend: 'gwsw_putten',
            rioned_put_slokop: 'gwsw_putten',
            rioned_put_spoelvoorziening: 'gwsw_putten',
            rioned_put_inlaat: 'gwsw_putten',
            rioned_put_vetafscheider: 'gwsw_putten',
            rioned_put_olie_benzineafscheider: 'gwsw_putten',
            rioned_put_slibafscheider: 'gwsw_putten',
            rioned_put_vetvangput: 'gwsw_putten',
            rioned_put_stuwconstructie: 'gwsw_putten',
            rioned_put_aansluiting: 'gwsw_putten',
            rioned_put_afsluitstuk: 'gwsw_putten',
            rioned_put_compartiment: 'gwsw_putten',
            rioned_put_infiltratieput: 'gwsw_putten',
            rioned_put_lozeput: 'gwsw_putten',
            rioned_put_uitlaatpunt: 'gwsw_putten',
            rioned_put_nooduitlaat: 'gwsw_putten',
            rioned_put_vacuumpompstation: 'gwsw_putten',
            rioned_put_vacuumopslagtank: 'gwsw_putten',
            rioned_put_inlaat_rioolput: 'gwsw_putten',
            rioned_put_mechanische_afsluiter: 'gwsw_putten',
            rioned_put_wervelventiel: 'gwsw_putten',
            rioned_put_debietmeter: 'gwsw_putten',
            rioned_put_inspectieput: 'gwsw_putten',
            rioned_put_knooppunt: 'gwsw_putten',
            rioned_put_rioolafsluiter: 'gwsw_putten',
            rioned_put_gecombineerde_straat_trottoirkolk: 'gwsw_putten',
            rioned_put_putschacht: 'gwsw_putten',
            rioned_put_enkelkerende_spindelschuif: 'gwsw_putten',
            rioned_put_wand: 'gwsw_putten',
            rioned_put_doorspuitput: 'gwsw_putten',
            rioned_put_duiker: 'gwsw_putten',
            rioned_put_olie_benzineafvangput: 'gwsw_putten',
            rioned_put_doorlaat: 'gwsw_putten',
            rioned_put_inlaat_oppervlaktewater: 'gwsw_putten',
            rioned_put_slibvangput: 'gwsw_putten',
            rioned_put_vijver: 'gwsw_putten',
            rioned_put_lamellenafscheider: 'gwsw_putten',
            rioned_aansluiting_kolk: 'gwsw_putten',
            rioned_aansluiting_lozingsput: 'gwsw_putten',
            rioned_aansluiting_verbindingsstuk: 'gwsw_putten',
            rioned_aansluiting_uitlaatpunt: 'gwsw_putten',
            rioned_aansluiting_t_stuk: 'gwsw_putten',
            rioned_aansluiting_slokop: 'gwsw_putten',
            rioned_aansluiting_inlaat: 'gwsw_putten',
            rioned_aansluiting_drainageput: 'gwsw_putten',
            rioned_aansluiting_aansluitpunt: 'gwsw_putten',
            rioned_aansluiting_troittoirkolk: 'gwsw_putten',
            rioned_aansluiting_straatkolk: 'gwsw_putten',
            rioned_aansluiting_inlaat_leiding: 'gwsw_putten',
            rioned_aansluiting_molgoot: 'gwsw_putten',
            pdok_waterschap_inspectieput: 'gwsw_putten',
            pdok_waterschap_nooduitlaat: 'gwsw_putten',
            pdok_waterschap_iba: 'gwsw_putten',
            pdok_waterschap_boostergemaal: 'gwsw_putten',
            pdok_waterschap_opvoergemaal: 'gwsw_putten',
            pdok_waterschap_rioolgemaal: 'gwsw_putten',
            pdok_waterschap_rwzi: 'gwsw_putten',
            pdok_waterschap_lozingspunt: 'gwsw_putten',
            pdok_waterschap_rioolafsluiter: 'gwsw_putten',
            pdok_waterschap_ontluchter: 'gwsw_putten',
            pdok_waterschap_overnamepunt: 'gwsw_putten',
            //DUOPP GWSW sets put onderdeel
            rioned_put_onderdeel_opening_in_wand: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_rioolafsluiter: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_afsluiter: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_lamellenafscheider: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_overstortdrempel: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_peilbuis: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_schildmuur: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_doorlaat: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_zonk: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_mechanische_afsluiter: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_compartiment: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_putschacht: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_terugslagklep: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_ontluchtingsunit: 'gwsw_put_onderdelen',
            rioned_put_overstortdrempel: 'gwsw_put_onderdelen',
            rioned_put_pompcapaciteit: 'gwsw_put_onderdelen',
            rioned_put_onderdeel_enkelkerende_spindelschuif:
                'gwsw_put_onderdelen',
            //DUOPP GWSW sets leiding onderdeel
            rioned_leiding_onderdeel_lining: 'gwsw_leiding_onderdelen',

            //Duopp GWSW sets Leidingen
            rioned_rioolstrengen_bergbezinkleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_bergbezinkbassin: 'gwsw_leidingen',
            rioned_rioolstrengen_randvoorziening: 'gwsw_leidingen',
            rioned_rioolstrengen_drain: 'gwsw_leidingen',
            rioned_rioolstrengen_kolkaansluitleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_drukleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_vacuumleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_duiker: 'gwsw_leidingen',
            rioned_rioolstrengen_overkluizing: 'gwsw_leidingen',
            rioned_rioolstrengen_hemelwater: 'gwsw_leidingen',
            rioned_rioolstrengen_waterdeel: 'gwsw_leidingen',
            rioned_rioolstrengen_infiltratie: 'gwsw_leidingen',
            rioned_rioolstrengen_dt_riool: 'gwsw_leidingen',
            rioned_rioolstrengen_dit_riool: 'gwsw_leidingen',
            rioned_rioolstrengen_infiltratievoorziening: 'gwsw_leidingen',
            rioned_rioolstrengen_loze_leiding: 'gwsw_leidingen',
            rioned_rioolstrengen_volgeschuimde_leiding: 'gwsw_leidingen',
            rioned_rioolstrengen_buiten_gebruik: 'gwsw_leidingen',
            rioned_rioolstrengen_mantelbuis: 'gwsw_leidingen',
            rioned_rioolstrengen_zinker: 'gwsw_leidingen',
            rioned_rioolstrengen_transportleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_vrijvervaltransportleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_bergingsleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_leiding: 'gwsw_leidingen',
            rioned_rioolstrengen_overig: 'gwsw_leidingen',
            rioned_rioolstrengen_overstortleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_perceelaansluitleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_aansluitleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_hwa_perceelaansluitleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_gemengde_perceelaansluitleiding:
                'gwsw_leidingen',
            rioned_rioolstrengen_dwa_perceelaansluitleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_persleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_vrijverval: 'gwsw_leidingen',
            rioned_rioolstrengen_leidingwerk: 'gwsw_leidingen',
            rioned_rioolstrengen_vuilwater: 'gwsw_leidingen',
            rioned_rioolstrengen_lijngoot: 'gwsw_leidingen',
            rioned_rioolstrengen_molgoot: 'gwsw_leidingen',
            rioned_rioolstrengen_goot: 'gwsw_leidingen',
            rioned_rioolstrengen_voedingskabel: 'gwsw_leidingen',
            rioned_rioolstrengen_middenspanningskabel: 'gwsw_leidingen',
            rioned_rioolstrengen_hoogspanningskabel: 'gwsw_leidingen',
            rioned_rioolstrengen_kabel: 'gwsw_leidingen',
            rioned_rioolstrengen_laagspanningskabel: 'gwsw_leidingen',
            rioned_rioolstrengen_spoelleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_geboordeleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_datakabel: 'gwsw_leidingen',
            rioned_rioolstrengen_geurfilterleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_onbekend: 'gwsw_leidingen',
            rioned_rioolstrengen_gemengd_riool: 'gwsw_leidingen',
            rioned_rioolstrengen_leidingsegment: 'gwsw_leidingen',
            rioned_rioolstrengen_drainagestelsel: 'gwsw_leidingen',
            rioned_rioolstrengen_stuwrioolleiding: 'gwsw_leidingen',
            rioned_rioolstrengen_roostergoot: 'gwsw_leidingen',
            rioned_rioolstrengen_uitlegger: 'gwsw_leidingen',
            rioned_rioolstrengen_lining: 'gwsw_leidingen',
            rioned_aansluiting_kolkaansluitleiding: 'gwsw_leidingen',
            rioned_aansluiting_dwa_perceelaansluitleiding: 'gwsw_leidingen',
            rioned_aansluiting_hwa_perceelaansluitleiding: 'gwsw_leidingen',
            rioned_aansluiting_lijngoot: 'gwsw_leidingen',
            rioned_aansluiting_aansluitleiding: 'gwsw_leidingen',
            rioned_aansluiting_gemengde_perceelaansluitleiding:
                'gwsw_leidingen',
            pdok_waterschap_datakabel: 'gwsw_leidingen',
            pdok_waterschap_kabel: 'gwsw_leidingen',
            pdok_waterschap_middenspanningskabel: 'gwsw_leidingen',
            pdok_waterschap_laagspanningskabel: 'gwsw_leidingen',
            pdok_waterschap_leiding: 'gwsw_leidingen',
            pdok_waterschap_ontluchtingsleiding: 'gwsw_leidingen',
            pdok_waterschap_persleiding: 'gwsw_leidingen',
            pdok_waterschap_leidingsegment: 'gwsw_leidingen',
            pdok_waterschap_vrijvervaltransportleiding: 'gwsw_leidingen',
            pdok_waterschap_vrijvervalleidingsegment: 'gwsw_leidingen',
            pdok_waterschap_aansluitleiding: 'gwsw_leidingen',
            //Duopp GWSW sets Gebied
            rioned_gebied_rioleringsgebied: 'gwsw_gebieden',
            rioned_gebied_bemalingsgebied: 'gwsw_gebieden',
            rioned_gebied_gemengd_systeem: 'gwsw_gebieden',
            rioned_gebied_gescheiden_systeem: 'gwsw_gebieden',
            rioned_gebied_verbeterd_gescheiden_systeem: 'gwsw_gebieden',
            rioned_gebied_absoluut_rioolstelsel: 'gwsw_gebieden',
            rioned_gebied_kwetsbaargebied: 'gwsw_gebieden',
            rioned_gebied_vuilwaterstelsel: 'gwsw_gebieden',
            rioned_gebied_hemelwaterstelsel: 'gwsw_gebieden',
            rioned_gebied_mechanisch_rioolstelsel: 'gwsw_gebieden',
            rioned_gebied_drainagestelsel: 'gwsw_gebieden',
            rioned_gebied_district: 'gwsw_gebieden',
            rioned_gebied_onderbemaling: 'gwsw_gebieden',
            rioned_gebied_kern: 'gwsw_gebieden',
            pdok_waterschap_zuiveringsgebied: 'gwsw_gebieden',
            pdok_waterschap_rioleringsgebied: 'gwsw_gebieden',
            //Duopp GWSW Voorzieningen
            rioned_voorziening_wadi: 'gwsw_voorzieningen',
            rioned_voorziening_rioolgemaal: 'gwsw_voorzieningen',
            rioned_voorziening_overstortput: 'gwsw_voorzieningen',
            rioned_voorziening_bergbezinkbassin: 'gwsw_voorzieningen',
            rioned_voorziening_pompunit: 'gwsw_voorzieningen',
            rioned_voorziening_lozingsput: 'gwsw_voorzieningen',
            rioned_voorziening_kruisingsput: 'gwsw_voorzieningen',
            rioned_voorziening_uitlaat_punt: 'gwsw_voorzieningen',
            rioned_voorziening_vetafscheider: 'gwsw_voorzieningen',
            rioned_voorziening_externe_overstortput: 'gwsw_voorzieningen',
            rioned_voorziening_rwzi: 'gwsw_voorzieningen',
            rioned_voorziening_bijzondere_putconstructie: 'gwsw_voorzieningen',
            rioned_voorziening_gemaal_natte_opstelling: 'gwsw_voorzieningen',
            rioned_voorziening_uitlaatconstructie: 'gwsw_voorzieningen',
            rioned_voorziening_pompput: 'gwsw_voorzieningen',
            rioned_voorziening_terugslagklep: 'gwsw_voorzieningen',
            rioned_voorziening_compartiment: 'gwsw_voorzieningen',
            rioned_voorziening_verbindingsstuk: 'gwsw_voorzieningen',
            rioned_voorziening_infiltratieput: 'gwsw_voorzieningen',
            rioned_voorziening_spoelvoorziening: 'gwsw_voorzieningen',
            rioned_voorziening_onbekend: 'gwsw_voorzieningen',
            rioned_voorziening_infiltratiebassin: 'gwsw_voorzieningen',
            rioned_voorziening_inlaat_oppervlaktewater: 'gwsw_voorzieningen',
            rioned_voorziening_doorlaat: 'gwsw_voorzieningen',
            rioned_voorziening_slibvangpunt: 'gwsw_voorzieningen',
            rioned_voorziening_iba_klasse2: 'gwsw_voorzieningen',
            rioned_voorziening_inspectieput: 'gwsw_voorzieningen',
            rioned_voorziening_blinde_put: 'gwsw_voorzieningen',
            rioned_voorziening_interne_overstortput: 'gwsw_voorzieningen',
            rioned_voorziening_infiltratiekrat: 'gwsw_voorzieningen',
            rioned_voorziening_zandvangput: 'gwsw_voorzieningen',
            rioned_voorziening_centrale_verdeelkast: 'gwsw_voorzieningen',
            rioned_voorziening_stuwconstructie: 'gwsw_voorzieningen',
            rioned_voorziening_kolk: 'gwsw_voorzieningen',
            rioned_voorziening_zinkerput: 'gwsw_voorzieningen',
            rioned_voorziening_iba: 'gwsw_voorzieningen',
            rioned_voorziening_inlaat: 'gwsw_voorzieningen',
            rioned_voorziening_vacuumpompstation: 'gwsw_voorzieningen',
            rioned_voorziening_slokop: 'gwsw_voorzieningen',
            rioned_voorziening_stuwput: 'gwsw_voorzieningen',
            rioned_voorziening_centrale_voedingskast: 'gwsw_voorzieningen',
            rioned_voorziening_fysiek_object: 'gwsw_voorzieningen',
            rioned_voorziening_steenwolkoffer: 'gwsw_voorzieningen',
            rioned_voorziening_infiltratiereservoir: 'gwsw_voorzieningen',
            rioned_voorziening_waterloop: 'gwsw_voorzieningen',
            rioned_voorziening_filterput: 'gwsw_voorzieningen',
            rioned_voorziening_vijver: 'gwsw_voorzieningen',
            rioned_voorziening_knooppunt: 'gwsw_voorzieningen',
            //Duopp afvoerpunten/relaties
            afvoerpunt_rioolgemaal_waterschap: 'gwsw_afvoerpunten',
            afvoerpunt_rioolgemaal: 'gwsw_afvoerpunten',
            afvoerpunt_mechanische_rioolstelsel_afvoer: 'gwsw_afvoerpunten',
            afvoerpunt_vrijverval_stelsel: 'gwsw_afvoerpunten',
            afvoerpunt_afleveringspunt: 'gwsw_afvoerpunten',
            afvoerpunt_afvoerpuntgebied: 'gwsw_afvoerpunten',
            afvoerrelatie_afvoernetwerk_mechanisch: 'gwsw_afvoerrelaties',
            afvoerrelatie_afvoernetwerk_vrijverval: 'gwsw_afvoerrelaties',
            afvoerrelatie_afvoernetwerk_toekomstig: 'gwsw_afvoerrelaties',
            blokkenrelatie_vrijverval_afvoerpunt_afvoerpunt:
                'gwsw_blokkenrelaties',
            blokkenrelatie_mechanisch_afvoerpunt_verbinding:
                'gwsw_blokkenrelaties',
            blokkenrelatie_mechanisch_afvoerpunt_afvoerpunt:
                'gwsw_blokkenrelaties',
            blokkenrelatie_mechanisch_afvoerpunt_ontvangstconstructie:
                'gwsw_blokkenrelaties',
            blokkenrelatie_vrijverval_afvoerpunt_ontvangstconstructie:
                'gwsw_blokkenrelaties',
            blokkenrelatie_vrijverval_ontvangstconstructie_afvoerpunt:
                'gwsw_blokkenrelaties',
            blokkenrelatie_mechanisch_ontvangstconstructie_afvoerpunt:
                'gwsw_blokkenrelaties',
            blokkenrelatie_mechanisch_afvoerpunt_ontvangstconstructie_onbekend:
                'gwsw_blokkenrelaties',
            blokkenschema_kentallen: 'gwsw_kentallen',
            //duopp riooltekeningen
            riooltekeningen_drukriolering: 'duopp_riooltekeningen',
            riooltekeningen_vrijverval: 'duopp_riooltekeningen',
            riooltekeningen_reiniging_inspectie: 'duopp_riooltekeningen',
            riooltekeningen_bijzondere_constructies: 'duopp_riooltekeningen',
            riooltekeningen_riooltekeningen: 'duopp_riooltekeningen',
            reiniging_inspectietekeningen: 'duopp_riooltekeningen',
            riooltekeningen_constructietekeningen: 'duopp_riooltekeningen',
            riooltekeningen_onderhoud_bermen: 'duopp_riooltekeningen',
            riooltekeningen_rioolaansluitingen: 'duopp_riooltekeningen',
            riooltekeningen_geboorde_leidingen: 'duopp_geboorde_leidingen',
            riooltekeningen_overig: 'duopp_riooltekeningen_overig',
            putfoto_algemeen: 'duopp_putfoto',
            putfoto_overstort: 'duopp_putfoto',
            putfoto_overstort_dichtgezet: 'duopp_putfoto',
            putfoto_randvoorziening: 'duopp_putfoto',
            putfoto_rwa_uitlaat: 'duopp_putfoto',
            rapportage: 'duopp_rapportage',
            //duopp huisaansluitschetsen
            huisaansluitschetsen: 'duopp_huisaansluitschetsen',
            huisaansluitschetsen_meerdere: 'duopp_huisaansluitschetsen',
            huisaansluitschetsen_niet_aanwezig: 'duopp_huisaansluitschetsen',
            bag_objecten_samengevoegd: 'duopp_huisaansluitschetsen',
            huisaansluitschetsen_archief: 'duopp_huisaansluitschetsen',
            //leiden huisaansluitschetsen
            huisaansluitschetsen_has: 'leiden_huisaansluitschetsen',
            huisaansluitschetsen_has_overig: 'leiden_huisaansluitschetsen',
            huisaansluitschetsen_nieuw: 'leiden_huisaansluitschetsen',
            huisaansluitschetsen_archief_leiden: 'leiden_huisaansluitschetsen',
            //maatregelen onderhoud
            onderhoud_maatregelen_buren_geen: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_goot: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_maaiveld: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_overstort: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_riolen: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_trottoir: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_zie_bu03: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_zie_bu08: 'maatregelen_onderhoud',
            onderhoud_maatregelen_buren_zie_l10: 'maatregelen_onderhoud',
            //hoosbuien buren
            hoosbuien_panden_t10_wit: 'hoosbuien_buren',
            hoosbuien_panden_t10_grijs: 'hoosbuien_buren',
            hoosbuien_panden_t10_groen: 'hoosbuien_buren',
            hoosbuien_panden_t10_oranje: 'hoosbuien_buren',
            hoosbuien_panden_t10_rood: 'hoosbuien_buren',
            hoosbuien_panden_t100_wit: 'hoosbuien_buren',
            hoosbuien_panden_t100_grijs: 'hoosbuien_buren',
            hoosbuien_panden_t100_groen: 'hoosbuien_buren',
            hoosbuien_panden_t100_oranje: 'hoosbuien_buren',
            hoosbuien_panden_t100_rood: 'hoosbuien_buren',
            hoosbuien_panden_t1000_wit: 'hoosbuien_buren',
            hoosbuien_panden_t1000_grijs: 'hoosbuien_buren',
            hoosbuien_panden_t1000_groen: 'hoosbuien_buren',
            hoosbuien_panden_t1000_oranje: 'hoosbuien_buren',
            hoosbuien_panden_t1000_rood: 'hoosbuien_buren',
            //Beoordeling Buren
            riolering_beoordeling_0: 'beoordeling_inspecties',
            riolering_beoordeling_1: 'beoordeling_inspecties',
            riolering_beoordeling_2: 'beoordeling_inspecties',
            riolering_beoordeling_3: 'beoordeling_inspecties',
            riolering_beoordeling_4: 'beoordeling_inspecties',
            riolering_beoordeling_5: 'beoordeling_inspecties',
            riolering_beoordeling_6: 'beoordeling_inspecties',
            riolering_beoordeling_null: 'beoordeling_inspecties',
            riolering_beoordeling_oudere_reviewdatum: 'beoordeling_inspecties',

            //wegbeheer
            wegbeheer_asfaltverharding: 'wegbeheer',
            wegbeheer_elementenverharding: 'wegbeheer',
            wegbeheer_betonverharding: 'wegbeheer',
            wegbeheer_halfverharding: 'wegbeheer',
            wegbeheer_onverhard: 'wegbeheer',
            wegbeheer_onbekend: 'wegbeheer',
            //Beheerpakketten
            rioned_beheerpakket_beregeningspomp: 'oms_h2go',
            rioned_beheerpakket_hdsr_gemaal: 'oms_h2go',
            rioned_beheerpakket_rwa_dwa_gemaal: 'oms_h2go',
            rioned_beheerpakket_oppervlaktewatergemaal: 'oms_h2go',
            rioned_beheerpakket_regenwatergemaal: 'oms_h2go',
            rioned_beheerpakket_rioolgemaal: 'oms_h2go',
            rioned_beheerpakket_vacuumgemaal: 'oms_h2go',
            rioned_beheerpakket_tunnelgemaal: 'oms_h2go',
            rioned_beheerpakket_drukriolering: 'oms_h2go',
            rioned_beheerpakket_drukriolering_cvk: 'oms_h2go',
            rioned_beheerpakket_fontein: 'oms_h2go',
            rioned_beheerpakket_vacuumbufferput: 'oms_h2go',
            rioned_beheerpakket_iba: 'oms_h2go',
            rioned_beheerpakket_cvk: 'oms_h2go',
            rioned_beheerpakket_stuwput: 'oms_h2go',
            rioned_beheerpakket_geurfilter: 'oms_h2go',
            rioned_beheerpakket_neerslagmeting: 'oms_h2go',
            rioned_beheerpakket_overstort: 'oms_h2go',
            rioned_beheerpakket_bergbezinkbassin: 'oms_h2go',
            //buren Groenvakken
            groenvakken_btl_2017_goed: 'buren_groenvakken',
            groenvakken_btl_2017_matig: 'buren_groenvakken',
            groenvakken_btl_2017_slecht: 'buren_groenvakken',
            groenvakken_btl_2017_niet_aanwezig: 'buren_groenvakken',
            groenvakken_btl_2017_naverkennen: 'buren_groenvakken',
            groenvakken_btl_2017_null: 'buren_groenvakken',
            groenvakken_btl_2017_maatregel_opm: 'buren_groenvakken',
            groenvakken_btl_2017_niet_op_pdf: 'buren_groenvakken',
            groenvakken_btl_2017_anders: 'buren_groenvakken',
            groenvakken_btl_2023_goed: 'buren_groenvakken',
            groenvakken_btl_2023_matig: 'buren_groenvakken',
            groenvakken_btl_2023_slecht: 'buren_groenvakken',
            groenvakken_btl_2023_opm_text: 'buren_groenvakken',
            groenvakken_btl_2023_nieuw: 'buren_groenvakken',
            groenvakken_btl_2023_anders: 'buren_groenvakken',
            groenvakken_btl_count: 'buren_groenvakken',
            //buren Groenbeheer
            groenbeheer_geovisia_groenvakken_totaal:
                'buren_geovisia_groenvakken',
            groenbeheer_geovisia_bomen_totaal: 'buren_geovisia_bomen',
            //buren Groenbeheer
            onderzoek_riosonic_goed: 'buren_riosonic',
            onderzoek_riosonic_fout: 'buren_riosonic',
            onderzoek_riosonic_nvt: 'buren_riosonic'
        };

        if (!clicks || clicks.length < 1) {
            this.gridService.openStatus.set('closed');
            return;
        }
        let sortedFeatures = undefined;
        sortedFeatures = [];
        this.exportFeatures = undefined;
        this.exportFeatures = [];

        clicks.forEach((click, index) => {
            if (click?.layers.length < 1) return;
            click.layers.forEach(layer => {
                let info: any = undefined;
                info = {};

                let combined = false;
                const combine_type = sortedFeatures.find(
                    f => f.typeName === layer.title
                );
                if (combine_type && combine_type.type === 'columns') {
                    combine_type.data = combine_type.data?.concat(
                        this.getColumnData(layer)
                    );
                    combined = true;
                }

                if (click.source && click.source.values_) {
                    const grid: GridItem[] | undefined =
                        click.source.get('grid');
                    let mapData = undefined;
                    if (grid?.length > 0) {
                        grid.forEach(g => {
                            if (
                                g.columnName.includes(',') &&
                                g.columnName.includes(layer.title) 
                            ) {
                                const combine_type = sortedFeatures.find(
                                    f => f.columnName === g.columnName
                                );
                                if (combine_type) {
                                    combine_type.data =
                                        combine_type.data.concat(
                                            this.getColumnData(layer)
                                        );

                                    const id =
                                        click.id +
                                        '.' +
                                        (g ? g.name : layer.title);
                                    this.exportFeatures[id] =
                                        this.exportFeatures[id].concat(
                                            layer.features
                                        );
                                    combined = true;
                                }

                                g.columnName.split(',').forEach(title => {
                                    if (title === layer.title) mapData = g;
                                });
                            } else {
                                if (g.columnName === layer.title) {
                                    mapData = g;
                                }
                            }
                        });
                    }

                    if (remapTypes[layer.title])
                        layer.title = remapTypes[layer.title];
    
                    if (combined) return;

                    // grid = {
                    //     id
                    //     columnNames
                    //     title
                    //     type: columns,piegraph,linegraph,bargraph
                    // }

                    // graph = {
                    // labels
                    // graph {
                    //      count
                    //      colors
                    // }
                    // }

                    info = new MatTableDataSource(this.getColumnData(layer));
                    info.rawData = this.getColumnData(layer);

                    if (mapData) {
                        if (
                            mapData.type === 'pie' ||
                            mapData.type === 'line' ||
                            mapData.type === 'bar'
                        ) {
                            info.title = mapData.name;
                            info.typeName = layer.title;
                            info.columnName = mapData.columnName;
                            info.id = click.id + '.' + mapData.name;
                            info.type = mapData.type;
                            info.labels = [];
                            info.graphInfo = mapData.graph;

                            info.columns = this.getColumnInfo(layer);
                            info.columnNames = info.columns.map(c => c.name);

                            const counting = {};
                            const count = [];

                            for (const currentObject of info.rawData) {
                                const result = currentObject[mapData.graph.x];
                                counting[result] = (counting[result] || 0) + 1;
                            }

                            count.push(...Object.values(counting));
                            info.labels.push(...Object.keys(counting));
                            info.labels.sort((a, b) => a - b);

                            info.graph = {
                                data: count,
                                backgroundColor: this.chartColors
                            };
                        } else {
                            info.title = mapData.name;
                            info.typeName = layer.title;
                            info.columnName = mapData.columnName;
                            info.id = click.id + '.' + mapData.name;
                            info.type = 'columns';

                            info.columnNames = mapData.columns.map(c => c.prop);

                            info.columns =
                                mapData.columns || this.getColumnInfo(layer);
                        }
                    } else {
                        // Fallback when there are no settings
                        info.title = layer.title;
                        info.typeName = layer.title;
                        info.id = click.id + '.' + layer.title;
                        info.type = 'columns';

                        const columnInfo = this.getColumnInfo(layer);
                        info.columnNames = columnInfo.map(c => c.name);

                        info.columns = columnInfo;
                    }
                } else {
                    // Settings for grid come from config, not used so much anymore
                    const configData = this.gridConfig.find(
                        obj => obj.columnName === layer.title
                    );
                    info.typeName = layer.title;
                    info.id = click.id + '.' + layer.title;
                    info.type = 'columns';

                    if (configData) {
                        info.title = configData.name;

                        info.columnNames = configData.columns.map(c => c.prop);

                        info.columns =
                            configData.columns || this.getColumnInfo(layer);
                    } else {
                        info.title = layer.title;

                        const columnInfo = this.getColumnInfo(layer);
                        info.columnNames = columnInfo.map(c => c.name);

                        info.columns = columnInfo;
                    }
                }

                // Hardcode override
                if (hardcodedTypes[info.title]) {
                    info.title = hardcodedTypes[info.title];
                }

                this.exportFeatures[info.id] = layer.features;

                info.sort = this.sorts[index];
                if (click.layers.length) sortedFeatures.push(info);
            });
        });

        this.features = sortedFeatures;

        // hacky way to make sure the status will be set after setting the features
        setTimeout(() => {
            if (this.features?.length > 0) {
                this.gridService.openStatus.set('open');
            } else {
                this.gridService.openStatus.set('closed');
            }
        }, 0);
    }

    private getColumnInfo(
        layer: GridLayer
    ): { name: string; prop: string; width: number }[] {
        if (!layer.features.length) {
            return [];
        }

        const feature = layer.features[0];
        const props = feature.getProperties();

        const columnWidth = 30;
        const excludedProperties = new Set([
            'type',
            'msGeometry',
            'geometry',
            'resource',
            'boundedBy',
            'geom',
            'x',
            'y'
        ]);

        const columns = Object.keys(props)
            .filter(p => !excludedProperties.has(p))
            .map(p => ({
                name: p,
                prop: p,
                width: columnWidth,
                type: 'text'
            }));

        return columns;
    }

    private getColumnData(layer: GridLayer): any[] {
        const data = layer.features.map(feature => {
            const {
                msGeometry,
                geometry,
                boundedBy,
                x,
                y,
                geom,
                ...properties
            } = feature.getProperties();

            this.originalFeatures.push(feature);

            properties.id_ = feature.getId();

            return properties;
        });

        return data;
    }

    toggle() {
        if (this.gridService.openStatus() == 'open') {
            this.gridService.openStatus.set('closed');
        } else {
            this.gridService.openStatus.set('open');
        }
    }

    sortColumn(event) {
        this.cdr.detectChanges();
    }

    editTable(table) {
        const dia = this.dialog.open(EditGridDialog, {
            width: '65vw',
            height: '75vh',
            data: table
        });

        dia.afterClosed().subscribe(result => {
            if (result) {
                let i = this.features.findIndex(f => f.id === result.id);
                this.features[i] = result;

                // Search id and change grid
                // this.customGrids.push(result);
                this.cdr.detectChanges();
            }
        });
    }

    onTabChange(event) {
        this.gridService.activeTab = event.index;
    }
}
