<ng-container *ngIf="!legendService.styleMap()">
    <!-- Toggle for managing maps -->
    <div class="state" (click)="manage.set(!manage())">
        <h5>
            <span>
                <fa-icon
                    [icon]="manage() ? faAngleDown : faAngleRight"
                ></fa-icon>
            </span>
            Kaart beheer
        </h5>
    </div>

    <!-- Map management section -->
    <ng-container *ngIf="manage()">
        <button
            mat-mini-fab
            *ngIf="!environment.public"
            class="saveButton"
            color="secondary"
            (click)="saveLegend()"
            matTooltip="Bewaar de huidige legenda."
        >
            <fa-icon [icon]="faSave"></fa-icon>
        </button>

        <ul
            id="legend"
            cdkDropList
            [cdkDropListData]="maps()"
            (cdkDropListDropped)="drop($event)"
        >
            <li cdkDrag cdkDragLockAxis="y" *ngFor="let map of maps()">
                <ng-container *ngIf="map.maps; else normalMap">
                    <span *cdkDragPreview>{{ map.name }}</span>

                    <cook-map-group
                        *ngIf="!map.pivot.background"
                        [mapGroup]="map"
                    >
                        <div
                            class="drag-handle"
                            slot="drag-handle"
                            cdkDragHandle
                        ></div>
                    </cook-map-group>
                </ng-container>

                <ng-template #normalMap>
                    <span *cdkDragPreview>{{ map.name }}</span>

                    <cook-map *ngIf="!map.pivot.background" [map]="map">
                        <div
                            class="drag-handle"
                            slot="drag-handle"
                            cdkDragHandle
                        ></div>
                    </cook-map>
                </ng-template>
            </li>
        </ul>

        <button
            mat-stroked-button
            color="secondary"
            (click)="openMapCreator()"
            *ngIf="
                authService.id() && configService.config()?.tools?.mapCreator
            "
        >
            Persoonlijke kaart toevoegen
        </button>
    </ng-container>

    <!-- Not used for Duopp -->
    <!-- <div class="state" (click)="dynLegendClick()">
        <h5>
            <span>
                <fa-icon
                    [icon]="
                        legendService.dynamicLegend()
                            ? faAngleDown
                            : faAngleRight
                    "
                ></fa-icon>
            </span>
            Legenda
        </h5>
    </div>

    <ul *ngIf="legendService.dynamicLegend()" id="dynamic-legend">
        <ng-container *ngFor="let layer of legendService.imageUrls()">
            <ng-container *ngIf="layer?.icons?.length">
                <h6>{{ layer?.name }}</h6>
                <li
                    *ngFor="
                        let icon of layer.icons | keyvalue;
                        trackBy: legendService.trackByIcon
                    "
                >
                    <img
                        alt="Aan het laden..."
                        [src]="icon?.value"
                        class="legendImage"
                    />
                </li>
            </ng-container>
        </ng-container>
    </ul> -->
</ng-container>

<!-- Styling section -->
<div class="styling" *ngIf="legendService.styleMap()">
    <styling></styling>
</div>
